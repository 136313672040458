// src/JsonDisplay.js
import React from "react";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { SplitPascalCaseToString } from "../../../../functions/testReportFuntions";

const Answers = ({ data }) => {
  return (
    <TableContainer align="left"  component={Paper} style={{ marginTop: 20 }}>
      <Typography variant="h6" gutterBottom style={{ padding: "16px", paddingBottom: 0 }}>
        {`Test ID: ${data.test_id}`}
      </Typography>
      <Typography variant="h6" gutterBottom style={{ padding: "16px", paddingTop: 0 }}>
        {`Username: ${data.username} (${data.test_type})`}
      </Typography>
      {data.answerObj?.parts?.map((part, partIndex) => (
        <div key={partIndex}>
          <Typography variant="h6" gutterBottom style={{ paddingLeft: "16px", paddingTop: 50 }}>
            {`Part ${part.part} - ${part.part_type}`}
          </Typography>
          {part.sections?.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              <Typography variant="subtitle1" style={{ paddingLeft: "16px", paddingTop: 30 }}>
                {`Section ID: ${section.section_id}`}
              </Typography>
              <Typography variant="subtitle1" style={{ paddingLeft: "16px" }}>
                {`Question type: ${SplitPascalCaseToString(section.question_type)}`}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Question Number</TableCell>
                    <TableCell>Answer</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {section.answers && Object.entries(section.answers).map(
                    ([key, value], index) => (
                      <TableRow key={index}>
                        <TableCell>{key}</TableCell>
                        <TableCell>
                          {Array.isArray(value) ? value.join(", ") : value}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </div>
          ))}
        </div>
      ))}
    </TableContainer>
  );
};

export default Answers;
